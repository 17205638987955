import React, { useEffect, useState } from 'react';
import './App.css';
import Header from './Header';
import Sidebar from './Sidebar';
import NFTContainer from './NFTContainer';
function App() {
  const [nftData, setNftData] = useState([]);
  const [traits, setTraits] = useState({});
  const [traitOptions, setTraitOptions] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState('Explorers');
  useEffect(() => {
    console.log('Fetching data for collection:', selectedCollection);
    fetchData(selectedCollection);
  }, [selectedCollection]);

  useEffect(() => {
    console.log('Traits changed:', traits);
    populateTraitDropdown();
  }, [traits]);

  const getRankClass = (rank) => {
    if (rank <= 25) return 'yellow';
    if (rank <= 100) return 'blue';
    if (rank <= 300) return 'purple';
    if (rank <= 600) return 'green';
    return 'grey';
  };

  const displayNFTs = (nfts) => {
    console.log('Displaying NFTs:', nfts);
    setNftData(nfts);
  };

const [hideImages, setHideImages] = useState(false);

const toggleImages = () => {
  console.log('toggleImages function called');
  setHideImages(!hideImages);
  console.log('hideImages toggled to:', !hideImages);
};
const fetchData = (collectionId) => {
  console.log('Fetching data for collection:', collectionId);
  fetch(`/jsondata/${collectionId}.json`)
    .then(response => response.json())
    .then(data => {
        console.log('Fetched data:', data);
        const top25 = data.slice(0, 25);
        setNftData(top25);
        parseTraits(data);
      })
      .catch(error => console.error('Error fetching NFT data:', error));
  };
  const fetchTop100 = () => {
    console.log('Fetching entire collection and sorting top 100...');
    window.scrollTo(0, 0); 
    fetchEntireCollection(selectedCollection)
      .then(data => {
        const sortedData = [...data].sort((a, b) => a.rank - b.rank); 
        const top100 = sortedData.slice(0, 100);
        displayNFTs(top100); 
      })
      .catch(error => console.error('Error fetching or sorting top 100 NFT data:', error));
  };
  const fetchEntireCollection = (collectionId, limit) => {
    console.log(`Fetching entire collection: ${collectionId} with limit: ${limit}`);
    return fetch(`/jsondata/${collectionId}.json`)
      .then(response => response.json())
      .then(data => {
        console.log('Fetched data:', data);
        let collectionData = data;
        if (limit) {
          collectionData = data.slice(0, limit);
        }
        return collectionData; 
      })
      .catch(error => {
        console.error('Error fetching NFT data:', error);
        throw error; 
      });
  };
  const parseTraits = (nfts) => {
    const newTraits = {};
    nfts.forEach(nft => {
      nft.attributes.forEach(attr => {
        if (!newTraits[attr.trait_type]) {
          newTraits[attr.trait_type] = {};
        }
        if (!newTraits[attr.trait_type][attr.value]) {
          newTraits[attr.trait_type][attr.value] = 1;
        } else {
          newTraits[attr.trait_type][attr.value]++;
        }
      });
    });
    console.log('Parsed Traits:', newTraits); 
    setTraits(newTraits); 
  };
  const populateTraitDropdown = () => {
    const options = Object.keys(traits).map(trait => (
      <option key={trait} value={trait}>{trait}</option>
    ));
    setTraitOptions(options);
  };
  const handleCollectionChange = (event) => {
    const collectionId = event.target.value;
    console.log('Collection changed:', collectionId);
    setSelectedCollection(collectionId); 
    fetchData(collectionId);
  };
  const handleTraitChange = (event) => {
    const selectedTrait = event.target.value;
    populateValueDropdown(selectedTrait);
  };
  const populateValueDropdown = (trait) => {
    const valueSelector = document.getElementById('value-selector');
    valueSelector.innerHTML = '<option>Select Value</option>';
    Object.keys(traits[trait]).forEach(value => {
      const option = document.createElement('option');
      option.value = value;
      option.textContent = `${value} (${traits[trait][value]})`;
      valueSelector.appendChild(option);
    });
    valueSelector.style.display = 'block';
  };

  const handleValueChange = (event) => {
    const selectedTrait = document.getElementById('trait-selector').value;
    const selectedValue = event.target.value;
    const filteredData = nftData.filter(nft =>
      nft.attributes.some(attr => attr.trait_type === selectedTrait && attr.value === selectedValue)
    );
    displayNFTs(filteredData);
  };
const handleResetDisplayClick = () => {
  console.log('Resetting display...');
  window.scrollTo(0, 0); 
  fetchData(selectedCollection);
};
  
const handleSortByTokenIdClick = () => {
    console.log('Sorting by Token ID...');
    window.scrollTo(0, 0); 

    fetchEntireCollection(selectedCollection)
      .then(data => {
        const sortedData = [...data].sort((a, b) => {
          const tokenIdA = parseInt(a.JsonTokenID, 10);
          const tokenIdB = parseInt(b.JsonTokenID, 10);
          return tokenIdA - tokenIdB;
        });
        displayNFTs(sortedData);
      })
      .catch(error => console.error('Error fetching or sorting NFT data:', error));
};
  const handleSortByRankClick = () => {
    console.log('Sorting by Rank...');
    window.scrollTo(0, 0); 
    fetchEntireCollection(selectedCollection)
      .then(data => {
        const sortedData = [...data].sort((a, b) => a.rank - b.rank);
        displayNFTs(sortedData);
      })
      .catch(error => console.error('Error fetching or sorting NFT data:', error));
  };
const handleSearchBoxInput = (event) => {
  const searchText = event.target.value.toLowerCase().trim();
  window.scrollTo(0, 0);
  fetchEntireCollection(selectedCollection)
    .then(data => {
      const filteredData = data.filter(nft => {
        return nft.JsonTokenID.toString().toLowerCase().includes(searchText) ||
               nft.attributes.some(attr => 
                 attr.value.toString().toLowerCase().includes(searchText)
               );
      });
      displayNFTs(filteredData);
    })
    .catch(error => console.error('Error fetching or filtering NFT data:', error));
};
const handleSearchRankTokenInput = (event) => {
  const searchValue = parseInt(event.target.value.trim());
  window.scrollTo(0, 0); 
  fetchEntireCollection(selectedCollection)
    .then(data => {
      console.log('Data received:', data);
      const filteredData = data.filter(nft =>
        nft.rank === searchValue
      );
      console.log('Filtered data:', filteredData);
      displayNFTs(filteredData);
    })
    .catch(error => console.error('Error fetching or filtering NFT data:', error));
};
 return (
    <div className="App">
      <Header />
    <Sidebar
  fetchTop100={fetchTop100}
  handleCollectionChange={handleCollectionChange}
  handleSortByTokenIdClick={handleSortByTokenIdClick}
  handleSortByRankClick={handleSortByRankClick}
  handleResetDisplayClick={handleResetDisplayClick}
  handleSearchBoxInput={handleSearchBoxInput}
  handleSearchRankTokenInput={handleSearchRankTokenInput}
  traits={traits}
  fetchEntireCollection={fetchEntireCollection}
  selectedCollection={selectedCollection}
  displayNFTs={displayNFTs}
  hideImages={hideImages}
  toggleImages={toggleImages} // Make sure toggleImages is passed down here
/>
      <NFTContainer nftData={nftData} getRankClass={getRankClass} hideImages={hideImages} />
    </div>
  );
}
export default App;